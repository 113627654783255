"use client";

import {
  AssetId,
  SupportedCollateralId,
  SupportedLoanCurrencyId,
} from "shared/build/src/SupportedCurrencies";
import { SecondaryButton } from "src/components/SecondaryButton";
import { BigFloat } from "shared/build/src/utils/BigFloat";
import { Adapter } from "shared/build/src/services/adapters";
import { LoanDetails } from "src/loan/components/details/LoanDetails";
import { LoanCurrencyRatesCollection } from "src/conversion/hooks";
import {
  formatToDisplayString,
  formatToDisplayStringWithCurrency,
  isFrontalSymbol,
  formatCurrency,
} from "src/formatting/functions";
import { useCalculator } from "./hooks/useCalculator";
import { CalculatorInputField } from "./components/CalculatorInputField";
import { LoanAmountSlider } from "./components/LoanAmountSlider";
import { CalculatorLtvIndicator } from "./components/CalculatorLtvIndicator";
import { PrimaryButton } from "src/components/PrimaryButton";
import CalculatorContainer from "./components/CalculatorContainer";

type VanirHomePageCalculatorProps = {
  adapter: Adapter;
  collateralCurrency: SupportedCollateralId;
  supportedCollateralCurrencies?: SupportedCollateralId[];
  onCollateralChanged?: (asset: SupportedCollateralId) => void;
  loanCurrencyCollection: LoanCurrencyRatesCollection;
};

export function VanirHomePageCalculator(props: VanirHomePageCalculatorProps) {
  const { collateralCurrency, supportedCollateralCurrencies } = props;

  const {
    loanCurrencies,
    loanCurrency,
    setLoanCurrency,
    collateralToLoanRate,
    collateral,
    collateralError,
    onCollateralChange,
    collateralConversionText,
    changeCollateral,
    loanAmount,
    loanAmountBigFloat,
    loanAmountError,
    setLoanAmount,
    onLoanAmountChange,
    minLoanInLoanCurrency,
    maxLoanInLoanCurrency,
    ltv,
    maxLtv,
    liqLtv,
    ltvValues,
    maxLtvTooltipText,
    liqLtvTooltipText,
    catchErrors,
    sliderPos,
    sliderClassName,
    detailsHidden,
    toggleDetails,
    loanDetailsProps,
  } = useCalculator({ isHomePageCalculator: true, ...props });

  return (
    <CalculatorContainer className="shadow-lg drop-shadow-md px-4">
      <h3 className={"font-title text-[2rem] text-subtitle"}>
        Loan Calculator
      </h3>
      <div id="input-fields-container" className="flex flex-col gap-5">
        <CalculatorInputField
          type="collateral"
          label="Collateral amount"
          displayNameOverride={{
            wBTC: "BTC",
          }}
          error={collateralError}
          inputProps={{
            maxLength: 16,
            value: collateral,
            onBlur: () => catchErrors(),
            onChange: (e) => {
              onCollateralChange(e, collateralToLoanRate, (value: string) =>
                setLoanAmount(value)
              );
            },
          }}
          selectedCurrency={collateralCurrency}
          setSelectedCurrency={(currency: AssetId) =>
            changeCollateral(currency as SupportedCollateralId)
          }
          currencies={supportedCollateralCurrencies}
          conversion={collateralConversionText(
            collateralToLoanRate,
            loanCurrency
          )}
        />
        <CalculatorInputField
          type="loan"
          label={"Loan amount"}
          error={loanAmountError}
          inputProps={{
            value: loanAmount,
            maxLength: 30,
            onBlur: catchErrors,
            onChange: onLoanAmountChange,
            placeholder: `Min.amount: ${formatToDisplayStringWithCurrency(
              loanCurrency,
              minLoanInLoanCurrency
            )}`,
          }}
          selectedCurrency={loanCurrency}
          setSelectedCurrency={(currency: AssetId) => {
            setLoanCurrency(currency as SupportedLoanCurrencyId);
          }}
          currencies={loanCurrencies}
          currencySymbolBeforeInput={
            isFrontalSymbol(loanCurrency)
              ? formatCurrency(loanCurrency, "")
              : ""
          }
          currencySymbolAfterInput={
            !isFrontalSymbol(loanCurrency)
              ? formatCurrency(loanCurrency, " ")
              : ""
          }
        />
      </div>
      <div id="bars-container" className="flex w-full flex-col gap-3">
        <h4 className="font-title text-2xl text-calculator-subtitle">
          Adjust your loan
        </h4>
        <p className="font-sans text-sm text-text">
          Adjust your loan to change your loan-to-value (LTV){" "}
        </p>
        <LoanAmountSlider
          loanAmount={loanAmountBigFloat.formatAndRound().toString()}
          loanCurrency={loanCurrency}
          minLoanInLoanCurrency={minLoanInLoanCurrency}
          maxLoanInLoanCurrency={maxLoanInLoanCurrency}
          sliderPos={sliderPos}
          sliderClassName={sliderClassName}
          catchErrors={() => catchErrors()}
          setLoanAmount={(amount) =>
            setLoanAmount(formatToDisplayString(amount))
          }
        />
        <div className="mt-3 w-full" />
        <CalculatorLtvIndicator
          ltvValues={ltvValues}
          label={"LTV - LOAN HEALTH"}
          currentLtv={ltv.mul(BigFloat.fromNumber(100)).formatAndRound(0)}
          maxLtv={maxLtv.mul(BigFloat.fromNumber(100)).formatAndRound()}
          liquidationLtv={liqLtv.mul(BigFloat.fromNumber(100)).formatAndRound()}
          maxLtvTooltipText={maxLtvTooltipText}
          liqLtvTooltipText={liqLtvTooltipText}
        />
      </div>

      <div
        id="buttons-container"
        className="mt-8 flex flex-row justify-between"
      >
        <SecondaryButton onClick={toggleDetails}>
          {detailsHidden ? "Show details" : "Hide details"}
        </SecondaryButton>
        <PrimaryButton>{"Create Loan"}</PrimaryButton>
      </div>
      {!detailsHidden && <LoanDetails {...loanDetailsProps} />}
    </CalculatorContainer>
  );
}
