"use client";
import { TextInput } from "src/components/TextInput";
import { useFormField } from "src/forms/formHelpers";
import {
  addEmailToWaitingList,
  sendWaitingListConfirmationToUser,
} from "../actions";
import RoundedButton from "src/components/RoundedButton";
import { localizedText } from "src/language/LocalizedTextData";
import { FormEvent, useState } from "react";
import { Spinner } from "src/components/Spinner";
import { getLocale } from "src/helpers/languageHelpers";

export function WaitingListForm(props: {
  buttonClass?: string;
  font?: string;
}) {
  const [email, setEmail, emailError, validateEmail, setEmailError] =
    useFormField("", "email");

  const [joined, setJoined] = useState(false);
  const [loading, setLoading] = useState(false);

  const locale = getLocale();

  const titleFont = props.font ?? "font-title";
  const textFont = props.font ?? "font-sans";

  const disabled = !email || emailError !== null;

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const error = validateEmail();

    if (error || emailError) {
      setEmailError(error);
      return;
    }

    setLoading(true);
    await sendWaitingListConfirmationToUser(email);
    await addEmailToWaitingList(email);
    setJoined(true);
    setLoading(false);
  }

  return (
    <div className="flex w-full max-w-3xl flex-col gap-20 px-5 py-16">
      <h1 id="title" className={"w-fit text-7xl text-subtitle " + titleFont}>
        Join the waitlist
      </h1>
      <p className={"-mt-10 w-[90%] text-xl text-text " + textFont}>
        Waitlist members will be the first to receive the invitation to private
        beta. Join waitlist now and get ready to start your first crypto loan.{" "}
      </p>
      <form
        onSubmit={(e) => onSubmit(e)}
        className="items-left mt-[-2rem] flex min-w-fit max-w-lg flex-col gap-16"
      >
        <div className="flex w-full flex-col">
          <TextInput
            id="email"
            type="email"
            error={emailError}
            value={email}
            onBlur={validateEmail}
            onChange={(e) => setEmail(e.target.value)}
            width={"full"}
            label={localizedText.waitingListPage.label2[locale]}
            placeholder={"email@email.com"}
          />
          <div className={emailError ? "" : "h-7"} />
        </div>
        {!joined ? (
          !loading ? (
            <RoundedButton
              type="submit"
              disabled={disabled}
              className={props.buttonClass}
            >
              Join waitlist{" "}
            </RoundedButton>
          ) : (
            <Spinner className="-mt-3" />
          )
        ) : (
          <div className={"-mt-5 text-text " + textFont}>✓ Successfully joined waitlist</div>
        )}
      </form>
    </div>
  );
}
